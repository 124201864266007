import Vue from 'vue'
import Vuex from 'vuex'

import http from '../api/http.js'
import ports from '../api/ports.js'

import router from '../router'

Vue.use(Vuex)

var _ = require('lodash');

export default new Vuex.Store({
  state: {
    user: {
      access_token: window.localStorage.getItem('access_token'),
      refresh_token: window.localStorage.getItem('refresh_token'),
      domain: window.localStorage.getItem('domain'),
    },
    dataUpload: JSON.parse(window.localStorage.getItem('data_upload')),
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_DATA: (state, data) => {
      state.dataUpload = data
    },
    SET_DOMAIN: (state, domain) => {
      state.user.domain = domain
    },
  },
  actions: {
    LOGIN(context, user) {
      context.commit('SET_USER', user)
      window.localStorage.setItem('access_token', user.access_token)
      window.localStorage.setItem('refresh_token', user.refresh_token)
      window.localStorage.removeItem('domain')
    },
    LOGOUT(context) {
      context.commit('SET_USER', {
        access_token: undefined,
        refresh_token: undefined,
      })
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('refresh_token')
      window.localStorage.removeItem('domain')
    },
    REFRESH(context, refresh_token) {

      http.post(ports.user.refresh_login,
        {
          refresh_token: refresh_token,
        }, res => {
          console.log('res', res);

          context.dispatch('LOGIN', {
            access_token: res.access_token,
            refresh_token: res.refresh_token,
          })

        }, err => {
          console.log('error', err);

          context.dispatch('LOGOUT');
          router.replace({
            path: '/login',
            // query: { redirect: router.currentRoute.fullPath }
          })

        })
    },
    ADD_PERSON(context, data) {
      let dataLocal = JSON.parse(window.localStorage.getItem('data_upload'));
      if (dataLocal != null) {
        console.log('原来有数据');
        let index = _.findIndex(dataLocal.bus_list, data.car);
        if (index != -1) {
          console.log('原来有数据，且有这辆车');
          dataLocal.bus_list[index].sign_up_ids.push(data.person);
          dataLocal.bus_list[index].users.push(data.user);
        } else {
          console.log('原来有数据，但是没有这辆车');
          data.car.sign_up_ids = [data.person];
          data.car.users = [data.user];
          dataLocal.bus_list.push(data.car);
        }
      } else {
        console.log('原来没有数据');
        dataLocal = {
          bus_list: [],
        };
        data.car.sign_up_ids.push(data.person);
        data.car.users.push(data.user);
        dataLocal.bus_list.push(data.car);
      }
      context.dispatch('RESET_DATA', dataLocal)
    },
    ADD_CAR(context, car) {
      let data = JSON.parse(window.localStorage.getItem('data_upload'));
      if (data != null) {
        console.log('原来有数据');
        data.bus_list.push(car);
      } else {
        console.log('原来没有数据');
        data = {
          bus_list: [],
        };
        data.bus_list.push(car);
      }
      context.dispatch('RESET_DATA', data)
    },
    EDIT_CAR(context, car) {
      let data = JSON.parse(window.localStorage.getItem('data_upload'));
      if (data != null) {
        console.log('原来有数据');
        let sample = {
          plate_number: car.plate_number,
          registration_time: car.registration_time,
        }
        let index = _.findIndex(data.bus_list, sample);
        if (index != -1) {
          console.log('原来有数据，且有这辆车');
          data.bus_list[index] = car;
        } else {
          console.log('原来有数据，但是没有这辆车');
          data.bus_list.push(car);
        }
      } else {
        console.log('原来没有数据');
        data = {
          bus_list: [],
        };
        data.bus_list.push(car);
      }
      context.dispatch('RESET_DATA', data)
    },
    DELETE_CAR(context, car) {
      let data = JSON.parse(window.localStorage.getItem('data_upload'));
      if (data != null) {
        console.log('原来有数据');
        _.remove(data.bus_list, function (sample) {
          return sample.plate_number === car.plate_number && sample.registration_time === car.registration_time;
        });
      } else {
        console.log('原来没有数据');
      }
      context.dispatch('RESET_DATA', data)
    },
    RESET_DATA(context, data) {
      context.commit('SET_DATA', data)
      window.localStorage.setItem('data_upload', JSON.stringify(data))
    },
    CLEAR_DATA(context) {
      context.commit('SET_DATA', null)
      window.localStorage.removeItem('data_upload')
    },
    CHANGE_DOMAIN(context, domain) {
      context.commit('SET_DOMAIN', domain)
      window.localStorage.setItem('domain', domain)
    },

    ADD_CODE(context, data) {
      let dataLocal = JSON.parse(window.localStorage.getItem('data_upload'));
      if (dataLocal != null) {
        console.log('原来有数据');
        let index = _.findIndex(dataLocal.bus_list, data.car);
        if (index != -1) {
          console.log('原来有数据，且有这辆车');
          dataLocal.bus_list[index].code_number = Number(dataLocal.bus_list[index].code_number) + 1;
          dataLocal.bus_list[index].users.push(data.user);
        } else {
          console.log('原来有数据，但是没有这辆车');
          data.car.code_number = "1";
          data.car.sign_up_ids = [];
          data.car.users = [data.user];
          dataLocal.bus_list.push(data.car);
        }
      } else {
        console.log('原来没有数据');
        dataLocal = {
          bus_list: [],
        };
        data.car.code_number = "1";
        data.car.sign_up_ids = [];
        data.car.users = [data.user];
        dataLocal.bus_list.push(data.car);
      }
      context.dispatch('RESET_DATA', dataLocal)
    },
  },
  modules: {
  },


  getters: {
    USER(state) {
      return state.user
    },
    DATAUPLOAD(state) {
      return state.dataUpload
    },
  },
})
