import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // component: Home,
    component: () => import('../views/Homepage.vue'),
    meta: {
      title: '游客信息录入',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录',
      requiresAuth: false,
    },
  },
  {
    path: '/domain',
    name: 'domain',
    component: () => import('../views/Domain.vue'),
    meta: {
      title: '选择市场',
      requiresAuth: true,
    },
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('../views/Car.vue'),
    meta: {
      title: '车辆录入',
      requiresAuth: true,
    },
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import('../views/Scan.vue'),
    meta: {
      title: '游客扫描',
      requiresAuth: true,
    },
  },
  {
    path: '/data',
    name: 'data',
    component: () => import('../views/Data.vue'),
    meta: {
      title: '本地数据',
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: '关于我们',
      requiresAuth: true,
    },
  },
  {
    path: '/park',
    name: 'Park',
    component: () => import(/* webpackChunkName: "about" */ '../views/Park.vue'),
    meta: {
      title: '领取停车券',
      requiresAuth: false,
    },
  },
  {
    path: '/couponCode',
    name: 'couponCode',
    component: () => import(/* webpackChunkName: "about" */ '../views/couponCode.vue'),
    meta: {
      title: '核销采购月餐券',
      requiresAuth: false,
    },
  },
  {
    path: '/grant',
    name: 'grant',
    component: () => import(/* webpackChunkName: "about" */ '../views/grant.vue'),
    meta: {
      title: '商铺知识产权信息',
      requiresAuth: false,
    },
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.meta.requiresAuth) {// 判断是否需要登录权限
    if (store.getters.USER.access_token) {// 判断是否登录
      console.log('登录了');
      if (store.getters.USER.domain) {// 判断是否选择公司
        console.log('选择公司了');
        next()
      } else {// 没选择公司则跳转到选择公司界面
        console.log('没选择公司');

        if (to.path !== '/domain') {
          next({
            path: '/domain',
            query: { redirect: to.fullPath }
          })
        } else {
          next()
        }

      }
    } else {// 没登录则跳转到登录界面
      console.log('没登录');
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {

    if (document.URL.match(/\?.*#/)) {
      let temp = document.URL.match(/\?.*#/)[0]
      if (temp.match(/=.*&/)) {
        // 解析Code
        let code = temp.match(/=.*&/)[0]
        code = code.substr(1, code.length) // 去掉 ?
        code = code.substr(0, code.length - 1)  // 去掉 #
        console.log(code);

        let url = document.URL;
        url = url.replace(temp.substr(0, temp.length - 1), '') + "?code=" + code;

        window.history.replaceState({}, 0, url)

        next({
          path: '/login',
          query: { code: code }
        })
      } else {
        next()
      }
    } else {
      next()
    }


  }
})

export default router
