export default {
  user: {
    login: '/api/system-service/login', // 登录接口
    refresh_login: '/api/system-service/refresh_token', //刷新token
    info: '/api/system-service/self/info', // 用户信息
    domain: '/api/system-service/dictionary/modules',//domain对照
    wx: '/api/public-service/wechat/authorize', //微信授权
    wxSignature: '/api/public-service/wechat/signature', //微信签名
  },
  business: {
    dataCheck: (domain) => `/api/travel-service/${domain}/registrationBus/pda/check`, //上传数据检查
    dataUpload: (domain) => `/api/travel-service/${domain}/registrationBus/pda2`, //上传数据
    codeUpload: (domain) => `/api/travel-service/${domain}/registrationBus/pda3`, //上传数据
  },
  park: {
    couponBind: (domain) => `/api/park-service/${domain}/coupon/donate/qrcode`, //领停车券
  },
  coupon: {
    couponScan: (id) => `/api/shop-service/DOMAIN-10/shop_wholesale_meal_coupons/${id}`, //扫餐券
  },
  store: {
    grant: (id) => `/api/shop-service/DOMAIN-10/grant_pic/${id}`, //知识产权
  }

}
