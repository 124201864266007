import axios from 'axios'
import store from '../store'

const TIME_OUT_MS = 60 * 1000 // 默认请求超时时间

export default {

  /*
 * @param url
 * @param data
 * @param response 请求成功时的回调函数
 * @param exception 异常的回调函数
 */
  put(url, params, response, exception) {
    axios({
      method: 'put',
      url: url,
      data: params,
      timeout: TIME_OUT_MS,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then(
      res => {
        response(res.data)
      }
    ).catch(
      (error) => {
        if (exception) {
          exception(error)
        } else {
          console.log(error)
        }
      }
    )
  },
  /*
 * @param url
 * @param data
 * @param response 请求成功时的回调函数
 * @param exception 异常的回调函数
 */
  delete(url, params, response, exception) {
    axios({
      method: 'delete',
      url: url,
      params: params,
      timeout: TIME_OUT_MS,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then(
      res => {
        response(res.data)
      }
    ).catch(
      (error) => {
        if (exception) {
          exception(error)
        } else {
          console.log(error)
        }
      }
    )
  },

  /*
   * @param url
   * @param data
   * @param response 请求成功时的回调函数
   * @param exception 异常的回调函数
   */
  post(url, data, response, exception) {
    axios({
      method: 'post',
      url: url,
      data: data,
      timeout: TIME_OUT_MS,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      }
    }).then(
      res => {
        response(res.data)
      }
    ).catch(
      (error) => {
        if (exception) {
          exception(error)
        } else {
          console.log(error)
        }
      }
    )
  },

  /*
   * get 请求
   * @param url
   * @param params
   * @param response 请求成功时的回调函数
   * @param exception 异常的回调函数
   */
  get(url, params, response, exception) {
    axios({
      method: 'get',
      url: url,
      headers: {
        "Content-Type": "application/json:charset=UTF-8",
      },
      timeout: TIME_OUT_MS,
      params: params,
    })
      .then(
        res => {
          response(res.data);
        }
      )
      .catch(
        (error) => {
          if (exception) {
            exception(error)
          } else {
            console.log(error)
          }
        }
      )
  },

  /*
   * get 请求
   * @param url
   * @param params
   * @param response 请求成功时的回调函数
   * @param exception 异常的回调函数
   */
  getfile(url, params, response, exception) {
    axios({
      method: 'get',
      url: url,
      headers: {
        "Content-Type": "application/octet-stream",
      },
      timeout: TIME_OUT_MS,
      params: params,
      responseType: 'arraybuffer'
    })
      .then(
        res => {
          response(res);
        }
      )
      .catch(
        (error) => {

          console.info(error);

          if (exception) {
            exception(error)
          } else {
            console.log(error)
          }
        }
      )
  },

}

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.params) {
      if (config.params.tokenUnuse) {
        return config;
      }
    }
    if (config.data) {
      if (config.data.tokenUnuse) {
        return config;
      }
    }

    if (store.getters.USER.access_token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = `Bearer  ${store.getters.USER.access_token}`;
    }
    return config;

  },
  err => {
    return Promise.reject(err);
  });

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {

    if (error.response) {
      switch (error.response.status) {
        case 401:

          console.log('401');

          if (store.getters.USER.refresh_token) {
            store.dispatch('REFRESH', store.getters.USER.refresh_token);
          }

          break;

        case 400:
          console.log('错误请求')
          break;
        case 403:
          console.log('拒绝访问')
          break;
        case 404:
          console.log('请求错误,未找到该资源')
          break;
        case 405:
          console.log('请求方法未允许')
          break;
        case 408:
          console.log('请求超时')
          break;
        case 500:
          console.log('服务器端出错')
          break;
        case 501:
          console.log('网络为未实现')
          break;
        case 502:
          console.log('网络错误')
          break;
        case 503:
          console.log('服务不可用')
          break;
        case 504:
          console.log('网络超时')
          break;
        case 505:
          console.log('http版本不支持该请求')
          break;

        default:
          console.log(`连接错误${error.response.status}`)
      }
      return Promise.reject(error.response.data)   // 返回接口返回的错误信息
    }
  });
